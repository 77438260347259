import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86')
];

export const server_loads = [0,7];

export const dictionary = {
		"/": [~16],
		"/about": [21],
		"/api/offer/[offerId]": [22],
		"/articles": [~23],
		"/articles/[id]": [~24],
		"/ask": [25],
		"/booking": [26],
		"/cancel": [27],
		"/chat-with-functions": [29],
		"/chat": [28],
		"/contactagent": [32],
		"/contact": [30],
		"/contact/thanks": [31],
		"/docuseal2": [~34],
		"/docusealtest": [35],
		"/docuseal": [33],
		"/favourites": [~36],
		"/forgot": [~37],
		"/howtobuy": [38],
		"/howtolist": [39],
		"/idea": [40],
		"/intro": [41],
		"/listings": [~42],
		"/login": [~43],
		"/logout": [~44],
		"/map": [~45],
		"/my/dashboard": [46,[7]],
		"/my/dashboard/bidding": [~47,[7]],
		"/my/dashboard/profile": [~48,[7]],
		"/my/dashboard/profile/edit": [49,[7]],
		"/my/dashboard/security": [~50,[7]],
		"/my/dashboard/support": [~51,[7]],
		"/my/messages": [~52,[8]],
		"/my/properties": [~53,[9]],
		"/my/properties/add": [~55,[9]],
		"/my/properties/edit": [56,[9]],
		"/my/properties/edit/[id]": [~57,[9]],
		"/my/properties/[id]": [54,[9]],
		"/my/toolkit": [~58],
		"/my/toolkit/[id]": [~59,[10]],
		"/my/welcome": [60,[11]],
		"/offernewSTOP": [63],
		"/offernewSTOP/[id]": [~64],
		"/offerseal": [65,[14],[15]],
		"/offerseal/[id]": [~66,[14],[15]],
		"/offer": [61,[12],[13]],
		"/offer/[id]": [~62,[12],[13]],
		"/pricingTest": [68],
		"/pricing": [67],
		"/privacy": [69],
		"/(listings)/propertyfull": [19,[2,5]],
		"/(listings)/propertyfull/[id]": [~20,[2,5,6]],
		"/(listings)/property": [17,[2,3]],
		"/(listings)/property/[id]": [~18,[2,3,4]],
		"/questions": [~70],
		"/register": [~71],
		"/rooms": [72],
		"/rss": [73],
		"/sdk": [74],
		"/search": [75],
		"/signature": [76],
		"/signnow/document/getdocument": [~77],
		"/signnow/fieldInvite": [~78],
		"/signnow/users": [~79],
		"/success": [80],
		"/taxes": [~81],
		"/testauth": [~82],
		"/toolkit": [83],
		"/transition": [84],
		"/walk": [~85],
		"/where": [86]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';