import * as SentrySvelte from '@sentry/svelte';
//import crypto from 'crypto';
import type { HandleClientError } from '@sveltejs/kit';

// Initialize the Sentry SDK here
SentrySvelte.init({
	dsn: "https://43c7c4569c2c42c582339d08142b605a@o4504709485232128.ingest.sentry.io/4504709567610880",

  });


  
export const handleError: HandleClientError = ({ error, event }) => {
	const errorId = crypto.randomUUID();
	/* SentrySvelte.captureException(error, {
		contexts: { sveltekit: { event, errorId } },
	}); */

	return {
		message: "An unexpected error occurred. We're working on it", errorId
		}
	};